import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import axios from 'axios';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import ApiConfig from '../ApiConfig';
import SocietyHeader from '../Society_Portal/SocietyHeader';
import './uploadData.css';

const UploadData = () => {
    const [file1, setFile1] = useState(null);
    const [file2, setFile2] = useState(null);
    const [errorMessages, setErrorMessages] = useState({});
    const [data, setData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [status, setStatus] = useState(1);
    const [comment, setComment] = useState('');
    const [vehicleNumberSearch, setVehicleNumberSearch] = useState('');
    const [flatSearch, setFlatSearch] = useState('');
    const [vehicleTypeFilter, setVehicleTypeFilter] = useState('');
    const [tagStatusFilter, setTagStatusFilter] = useState('');
    const [showEditModal, setShowEditModal] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [tags, setTags] = useState([]);
    const [historyData, setHistoryData] = useState([]);
    const [showHistoryModal, setShowHistoryModal] = useState(false);
    const [selectedVehicleNumber, setSelectedVehicleNumber] = useState('');
    const [tagCodeSearch, setTagCodeSearch] = useState('');
    const [statusFilter, setStatusFilter] = useState('');
    const [showWarningModal, setShowWarningModal] = useState(false);
    
    function date_time_format(in_dateTime) {
        try {
            if (!moment(in_dateTime).isValid()) {
                throw new Error('Invalid date-time format');
            }
            return momentTimezone.tz(in_dateTime,'Asia/Kolkata').format('DD-MMM-YYYY HH:mm:ss.SSS');
        } catch (error) {
            console.log(error, 'date_time_format error with input: ' + in_dateTime);
            return in_dateTime;
        }
    }


    const [editForm, setEditForm] = useState({
        flat: '',
        vehicleNumber: '',
        parkingName: '',
        vehicleType: '',
        tagId: '',
        tagEpc: ''
    });
    const isLogged = Boolean(localStorage.getItem("isLoggedIn"))

    const handleFileChange1 = (event) => {
        setFile1(event.target.files[0]);
    };

    const handleFileChange2 = (event) => {
        setFile2(event.target.files[0]);
    };

    const handleSubmit1 = async (event) => {
        event.preventDefault();
        if (!file1) {
            setErrorMessages({ name: "file1", message: "Please select a file to upload" });
            return;
        }

        const formData = new FormData();
        formData.append('file', file1);

        try {
            const response = await axios.post(`${ApiConfig.baseUrl}api/upload-hanging-garden-data`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
            if (response.status === 200) {
                alert('Data uploaded successfully');
                fetchData();
            }
        } catch (error) {
            console.error('Error uploading file:', error);
            setErrorMessages({ name: "upload1", message: "Error uploading file. Please try again." });
        }
    };

    const handleSubmit2 = async (event) => {
        event.preventDefault();
        if (!file2) {
            setErrorMessages({ name: "file2", message: "Please select a file to upload" });
            return;
        }

        const formData = new FormData();
        formData.append('file', file2);

        try {
            const response = await axios.post(`${ApiConfig.baseUrl}update-tags`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
            if (response.status === 200) {
                alert('Tags updated successfully');
            }
        } catch (error) {
            console.error('Error uploading file:', error);
            setErrorMessages({ name: "upload2", message: "Error uploading file. Please try again." });
        }
    };

    const fetchData = async () => {
        try {
            const response = await axios.get(`${ApiConfig.baseUrl}hangingGardenData`);
            setData(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleShowModal = (row) => {
        setSelectedRow(row);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setShowEditModal(false);
        setShowConfirmModal(false)
    };

    const handleConfirmStatusChange = async () => {
        try {
            const response = await axios.put(`${ApiConfig.baseUrl}api/update-hanging-garden-status`, {
                id: selectedRow.id,
                status,
                comment
            });

            if (response.status === 200) {
                alert('Status updated successfully');
                fetchData();
                handleCloseConfirmModal();
            }
        } catch (error) {
            console.error('Error updating status:', error);
        }
    };

    const handleShowConfirmModal = () => {
        setShowConfirmModal(true);
    };

    const handleCloseConfirmModal = () => {
        console.log("close")
        setShowConfirmModal(false);
        setShowModal(false);
    };
    const handleShowEditModal = (row) => {
        setSelectedRow(row);
        setEditForm({
            flat: row.flat,
            vehicleNumber: row.vehicleNumber,
            parkingName: row.parkingName,
            vehicleType: row.vehicleType,
            tagId: row.tagId,
            tagEpc: row.tagEpc,
            tagCode: row.tagCode || '',
        });
        setShowEditModal(true);
    };

    const handleVehicleTypeChange = (e) => {
        const { name, value } = e.target;
        setEditForm(prevForm => {
            const filteredTags = tags.filter(tag => tag.VehicleType === value);
            return {
                ...prevForm,
                [name]: value,
                tagCode: filteredTags.length > 0 ? filteredTags[0]['Tag Code'] : ''
            };
        });
    };


    const handleCloseEditModal = () => {
        setShowEditModal(false);
    };

    const handleEditFormChange = (event) => {
        const { name, value } = event.target;

        setEditForm(prevForm => {
            let updatedForm = { ...prevForm, [name]: value };

            if (name === "tagCode") {
                // Find the selected tag based on the tag code
                const selectedTag = tags.find(tag => tag.TagCode === value);

                if (selectedTag) {
                    // Update the tagId with the corresponding Tag ID
                    updatedForm.tagId = selectedTag.TID;
                    updatedForm.tagEpc = selectedTag.EpcID;
                } else {
                    // Clear the tagId if no matching tag is found
                    updatedForm.tagId = "";
                    updatedForm.tagEpc = "";
                }
            }

            return updatedForm;
        });
    };


    const handleEditSubmit = async (event) => {
        event.preventDefault();
        
        // Check if tagId or tagEpc is empty
        if (!editForm.tagId.trim() || !editForm.tagEpc.trim()) {
            setShowWarningModal(true);
            return;
        }

        await saveData();
    };

    const saveData = async () => {
        try {
            const response = await axios.put(`${ApiConfig.baseUrl}api/update-hanging-garden-data`, {
                id: selectedRow.id,
                ...editForm
            });

            if (response.status === 200) {
                alert('Data updated successfully');
                fetchData();
                handleCloseEditModal();
            } else {
                console.log(response.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 409) {
                alert(error.response.data);
            } else {
                console.error('Error updating data:', error);
            }
        }
    };

    const handleWarningConfirm = () => {
        setShowWarningModal(false);
        saveData();
    };

    const handleWarningCancel = () => {
        setShowWarningModal(false);
    };

    const handleDelete = (id) => {
        setSelectedId(id);
        setShowConfirm(true);
    };

    const confirmDelete = async () => {
        try {
            console.log(`Deleting record with ID: ${selectedId}`);
            const response = await axios.delete(`${ApiConfig.baseUrl}api/delete-hanging-garden-data/${selectedId}`);
    
            if (response.status === 200) {
                alert('Record deleted successfully');
                fetchData();
                setShowConfirm(false);
            }
        } catch (error) {
            console.error('Error deleting record:', error);
            alert('Error deleting record. Please try again.');
        }
    };
    

    const cancelDelete = () => {
        setShowConfirm(false);
    };
    // const getTagStatus = (row) => {
    //     if ((row.tagId && row.tagId.length < 10) || (row.tagEpc && row.tagEpc.length < 10)) {
    //         return 'Not Available';
    //     }
    //     return (row.tagId && row.tagEpc) ? 'Available' : 'Not Available';
    // };
    const getTagStatus = (row) => {
        if (row.tagId && row.tagId.length < 10) {
            return 'Not Available';
        }
        return (row.tagId) ? 'Available' : 'Not Available';
    };


    const renderErrorMessage = (name) =>
        name === errorMessages.name && (
            <div className="error">{errorMessages.message}</div>
        );

    const vehicleTypes = [...new Set(data.map(row => row.vehicleType))];
    const tagStatuses = ['Available', 'Not Available'];

    const fetchTags = async () => {
        try {
            const response = await axios.get(`${ApiConfig.baseUrl}tagDetails`);
            if (response.data.length>0){
                setTags(response.data[0]);
            }
                    
        } catch (error) {
            console.error('Error fetching tags:', error);
        }
    };

    useEffect(() => {
        fetchTags();
    }, []);

    if (!isLogged) {
        return <Navigate to={"/"} replace={true} />
    }

    const fetchVisitorHistory = async (vehicleNumber) => {
        try {
            const response = await axios.get(`${ApiConfig.baseUrl}api/visitor/getVisitorHistory`, {
                params: { vehicleNumber }
            });
            if (response.data.success) {
                setHistoryData(response.data.data);
            } else {
                console.error('Failed to fetch history:', response.data.message);
            }
        } catch (error) {
            console.error('Error fetching visitor history:', error);
        }
    };

    const handleShowHistoryModal = (vehicleNumber) => {
        setSelectedVehicleNumber(vehicleNumber);
        fetchVisitorHistory(vehicleNumber);
        setShowHistoryModal(true);
    };
    
    const handleCloseHistoryModal = () => {
        setShowHistoryModal(false);
    };
    
    

    const handleExportToExcel = () => {
        const dataToExport = filteredData.map(row => ({
            Flat: row.flat,
            VehicleNumber: row.vehicleNumber,
            VehicleType: row.vehicleType,
            TagStatus: getTagStatus(row),
            TagId: row.tagId,
            TagEpc: row.tagEpc,
            TagCode:row.tagCode,
            Status: row.status === 1 ? 'Active' : 'Inactive'
        }));

        const ws = XLSX.utils.json_to_sheet(dataToExport);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "HangingGardenData");
        XLSX.writeFile(wb, "HangingGardenData.xlsx");
    };

    const filteredData = data.filter(row => {
        const isActive = row.status === 1 ;
        const isInActive = row.status === 2;

        // Apply filters based on the new status filter
        const statusMatch = statusFilter === ''
            || (statusFilter === 'Active' && isActive)
            || (statusFilter === 'InActive' && isInActive);

        return (
            statusMatch &&
            row.vehicleNumber.toLowerCase().includes(vehicleNumberSearch.toLowerCase()) &&
            row.flat.toLowerCase().includes(flatSearch.toLowerCase()) &&
            (vehicleTypeFilter ? row.vehicleType === vehicleTypeFilter : true) &&
            (tagStatusFilter ? getTagStatus(row) === tagStatusFilter : true) &&
            (tagCodeSearch ? row.tagCode && row.tagCode.toLowerCase().includes(tagCodeSearch.toLowerCase()) : true)
        );
    });

    const formatDateTime = (dateTime) => {
        const date = new Date(dateTime);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
    
        return `${day}/${month}/${year}, ${hours}:${minutes}:${seconds}`;
    };

    

    return (
        <div >
            <SocietyHeader />
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="SlotMas_form">
                            <div className="form_head d-flex justify-content-center align-items-center position-relative">
                                <h5>Upload Data & Update Tags</h5>
                                <Button variant="primary" onClick={handleExportToExcel} className="ml-auto position-absolute" style={{ right: 10 }}>
                                    Export to Excel
                                </Button>
                            </div>

                            <div className="form_body mt-3">

                                <form onSubmit={handleSubmit1} className="mb-4">
                                    <div className="flex-container">
                                        <input type="file" className="form-control" onChange={handleFileChange1} required />
                                        <button type="submit" className="btn btn-success">
                                            Upload Data
                                        </button>
                                    </div>
                                    {renderErrorMessage("file1")}
                                </form>

                                {/* <form onSubmit={handleSubmit2}>
                                    <div className="flex-container">
                                        <input type="file" className="form-control" onChange={handleFileChange2} required />
                                        <button type="submit" className="btn btn-success">
                                            Upload Tags
                                        </button>
                                    </div>
                                    {renderErrorMessage("file2")}
                                </form> */}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mt-5">
                    <div className="col-md-1"></div>
                    <div className="col-md-10">
                        <div className="mb-3">
                            <div className="row">
                                <div className="col-md-2">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search by Vehicle Number"
                                        value={vehicleNumberSearch}
                                        onChange={(e) => setVehicleNumberSearch(e.target.value)}
                                    />
                                </div>
                                <div className="col-md-2">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search by Flat"
                                        value={flatSearch}
                                        onChange={(e) => setFlatSearch(e.target.value)}
                                    />
                                </div>
                                <div className="col-md-2">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search by Tag Code"
                                        value={tagCodeSearch}
                                        onChange={(e) => setTagCodeSearch(e.target.value)}
                                    />
                                </div>
                                <div className="col-md-2">
                                    <Form.Control
                                        as="select"
                                        value={vehicleTypeFilter}
                                        onChange={(e) => setVehicleTypeFilter(e.target.value)}

                                    >
                                        <option value="">Select Vehicle Type</option>
                                        {vehicleTypes.map((type, index) => (
                                            <option key={index} value={type}>
                                                {type}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </div>
                                
                                <div className="col-md-2">
                                    <Form.Control
                                        as="select"
                                        value={tagStatusFilter}
                                        onChange={(e) => setTagStatusFilter(e.target.value)}
                                    >
                                        <option value="">Select Tags</option>
                                        {tagStatuses.map((status, index) => (
                                            <option key={index} value={status}>
                                                {status}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </div>
                                <div className="col-md-2">
                                    <Form.Control
                                        as="select"
                                        value={statusFilter}
                                        onChange={(e) => setStatusFilter(e.target.value)}
                                    >
                                        <option value="">All</option>
                                        <option value="Active">Active</option>
                                        <option value="InActive">InActive</option>
                                    </Form.Control>
                                </div>
                            </div>
                        </div>
                        <div className="table-container">
                            <table className="table table-bordered table-hover table-striped ">
                                <thead className="table-header">
                                    <tr>
                                        <th>S.No</th>
                                        <th>Flat</th>
                                        <th>Vehicle Number</th>
                                        <th>Parking Name</th>
                                        <th>Vehicle Type</th>
                                        <th>Tags</th>
                                        <th>Tag Code</th>
                                        <th>Comment</th>
                                        <th>Status</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredData.map((row, index) => (
                                        <tr key={row.id}>
                                            <td>{index + 1}</td>
                                            <td>{row.flat}</td>
                                            <td>{row.vehicleNumber}</td>
                                            <td>{row.parkingName}</td>
                                            <td>{row.vehicleType}</td>
                                            <td>{getTagStatus(row)}</td>
                                            <td>{row.tagCode}</td>
                                            <td>{row.comment}</td>
                                            <td>{row.status == 1 ? 'Active' : 'Inactive'}</td>
                                            <td>
                                                <Button variant="info" onClick={() => handleShowEditModal(row)}>Edit</Button>
                                                <Button
                                                    variant={row.status == 1 ? "warning" : "success"}
                                                    onClick={() => handleShowModal(row)}
                                                >
                                                    {row.status == 1 ? 'Inactive' : 'Active'}
                                                </Button>
                                                <Button
                                                    variant="danger"
                                                    onClick={() => handleDelete(row.id)}
                                                >
                                                    Delete
                                                </Button>

                                                <Button
                                                    variant="secondary"
                                                    onClick={() => handleShowHistoryModal(row.vehicleNumber)}
                                                >
                                                    History
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-md-1"></div>
                </div>
            </div>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header >
                    <Modal.Title>Update Status</Modal.Title>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleCloseConfirmModal}
                        aria-label="close"
                        style={{ marginLeft: 'auto' }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Modal.Header>

                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formStatus">
                            <Form.Label>Status</Form.Label>
                            <Form.Control
                                as="select"
                                value={status}
                                onChange={(e) => setStatus(Number(e.target.value))}
                            >
                                <option value={1}>Active</option>
                                <option value={2}>Inactive</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="formComment">
                            <Form.Label>Comment</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter reason for status change"
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                            />
                        </Form.Group>
                    </Form>
                    <div className="mt-3">
                        <Button variant="secondary" onClick={handleCloseModal}>
                            Close
                        </Button>
                        <Button
                            variant="primary"
                            onClick={handleShowConfirmModal}
                            className="ms-2"
                        >
                            Confirm
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>

            {/* Confirm Modal */}
            <Modal show={showConfirmModal} onHide={handleCloseConfirmModal}>
                <Modal.Header>
                    <Modal.Title>Confirm Status Change</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to change the status?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseConfirmModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleConfirmStatusChange}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>


            {/* Edit Modal */}
            <Modal show={showEditModal} onHide={handleCloseEditModal}>
                <Modal.Header>
                    <Modal.Title>Edit Details</Modal.Title>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleCloseEditModal}
                        aria-label="close"
                        style={{ marginLeft: 'auto' }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleEditSubmit}>
                        <Form.Group controlId="formFlat">
                            <Form.Label>Flat</Form.Label>
                            <Form.Control
                                type="text"
                                name="flat"
                                value={editForm.flat}
                                onChange={handleEditFormChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="formVehicleNumber">
                            <Form.Label>Vehicle Number</Form.Label>
                            <Form.Control
                                type="text"
                                name="vehicleNumber"
                                value={editForm.vehicleNumber}
                                onChange={handleEditFormChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="formParkingName">
                            <Form.Label>Parking Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="parkingName"
                                value={editForm.parkingName}
                                onChange={handleEditFormChange}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Vehicle Type</Form.Label>
                            <Form.Control
                                as="select"
                                name="vehicleType"
                                value={editForm.vehicleType}
                                onChange={handleVehicleTypeChange}
                                required
                            >
                                <option value="">Select Vehicle Type</option>
                                {vehicleTypes.map((type, index) => (
                                    <option key={index} value={type}>
                                        {type}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>

                        {/* <Form.Group>
                            <Form.Label>Tag Code</Form.Label>
                            <Form.Control
                                as="select"
                                name="tagCode"
                                value={editForm.tagCode}
                                onChange={handleEditFormChange}
                                required
                            >
                                <option value="">Select Tag Code</option>
                                {tags
                                    .filter(tag => tag.VehicleType === editForm.vehicleType)
                                    .map((tag, index) => (
                                        <option key={index} value={tag['Tag Code']}>
                                            {tag['Tag Code']}
                                        </option>
                                    ))}
                            </Form.Control>
                        </Form.Group> */}

                        <Form.Group>
                            <Form.Label>Tag Code</Form.Label>
                            <Form.Control
                                as="select"
                                name="tagCode"
                                value={editForm.tagCode}
                                onChange={handleEditFormChange}
                                // required
                            >
                                <option value="">Select Tag Code</option>
                                {tags.map((tag, index) => (
                                    <option key={index} value={tag.TagCode}>
                                        {tag.TagCode}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>


                        {/* <Form.Group controlId="formTagId">
                            <Form.Label>Tag ID</Form.Label>
                            <Form.Control
                                type="text"
                                name="tagId"
                                value={editForm.tagId}
                                onChange={handleEditFormChange}
                            // readOnly // Make it read-only since it's auto-filled
                            />
                        </Form.Group>
                        <Form.Group controlId="formTagEpc">
                            <Form.Label>Tag EPC</Form.Label>
                            <Form.Control
                                type="text"
                                name="tagEpc"
                                value={editForm.tagEpc}
                                onChange={handleEditFormChange}
                            />
                        </Form.Group> */}

                        <Form.Group controlId="formTagId">
    <Form.Label>Tag ID</Form.Label>
    <Form.Control
        type="text"
        name="tagId"
        value={editForm.tagId}
        onChange={handleEditFormChange}
        maxLength={24}
        // isInvalid={editForm.tagId.length > 0 && editForm.tagId.length !== 24}
    />
    <Form.Control.Feedback type="invalid">
        Tag ID must be exactly 24 characters long.
    </Form.Control.Feedback>
</Form.Group>

<Form.Group controlId="formTagEpc">
    <Form.Label>Tag EPC</Form.Label>
    <Form.Control
        type="text"
        name="tagEpc"
        value={editForm.tagEpc}
        onChange={handleEditFormChange}
        maxLength={24}
        // isInvalid={editForm.tagEpc.length > 0 && editForm.tagEpc.length !== 24}
    />
    <Form.Control.Feedback type="invalid">
        Tag EPC must be exactly 24 characters long.
    </Form.Control.Feedback>
</Form.Group>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleCloseEditModal}>
                                Close
                            </Button>
                            <Button variant="primary" type="submit">
                                Save Changes
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal.Body>
            </Modal>

            <Modal show={showConfirm} onHide={cancelDelete}>
                <Modal.Header >
                    <Modal.Title>Confirm Delete</Modal.Title>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={cancelDelete}
                        aria-label="close"
                        style={{ marginLeft: 'auto' }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this record?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={cancelDelete}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={confirmDelete}>
                        Yes, Delete
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showHistoryModal} onHide={handleCloseHistoryModal}>
            <Modal.Header >
                <Modal.Title>Visitor History</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className="table-container">

                <table className="table table-bordered table-hover table-striped">
                    {/* <thead> */}
                    <thead className="table-header">

                        <tr>
                            <th>Vehicle Number</th>
                            <th>Visited Gate</th>
                            <th>Visited Time</th>
                        </tr>
                    </thead>
                    <tbody>
                        {historyData.map((entry, index) => (
                            <tr key={index}>
                                <td>{entry.VehicleNumber}</td>
                                <td>{entry.VisitedGate}</td>
                                <td>{date_time_format(entry.VisitedTime)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={handleCloseHistoryModal}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>

        <Modal show={showWarningModal} onHide={handleWarningCancel}>
                <Modal.Header closeButton>
                    <Modal.Title>Warning</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Tag ID and/or EPC ID is empty. Do you want to proceed with saving?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleWarningCancel}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleWarningConfirm}>
                        Save Anyway
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    );
};

export default UploadData;
